<nav class="navbar navbar-inverse">
  <div class="container-fluid" style="padding-left: 15px;padding-right: 15px">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#"><img src="../../assets/images/logo.png" alt="logo"></a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">

      <ul class="nav navbar-nav navbar-right">
       <li><a>ABOUT</a></li>
       <li><a>NEWS</a></li>
       <li><a>CONTACT</a></li>
<!--       <li><a routerLink="/create" class="signUp">RESTAURANT SIGNUP</a></li>-->
        <li>
          <div class="dropdown">
            <a class="signUp dropdown-toggle dropdownBtn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">RESTAURANT SIGNUP</a>
<!--            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--              Dropdown button-->
<!--            </button>-->
            <div class="dropdown-menu dropdownMenuButtonMenu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" routerLink="/create">Pakistan</a>
              <a class="dropdown-item" routerLink="/create-canada">Canada</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<section class="headerSection container-fluid">

  <h1 class="headerTextHeading">FLASH FOOD<span class="rotate" style="color: #ed322c">  DEALS APP</span> </h1>

  <div class="hidden-xs">
    <p>Available on:</p>
    <a href="https://apps.apple.com/app/twidlee/id1495805483" target="_blank" class="page-scroll">
      <img src="../../assets/images/appleStoreWhite.png" class="appleStoreImg">
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.twidlee.android" target="_blank" class="page-scroll">
      <img src="../../assets/images/playStoreWhite.png"  class="playStoreImg">
    </a>
  </div>

  <div class="appIcons visible-xs" style="">
    <p>Available on:</p>
    <a href="https://apps.apple.com/app/twidlee/id1495805483" target="_blank" class="page-scroll">
      <img src="../../assets/images/appleStoreWhite.png" class="appleStoreImg">
    </a>
    <a href="https://play.google.com/store/apps/details?id=com.twidlee.android" target="_blank" class="page-scroll">
      <img src="../../assets/images/playStoreWhite.png"  class="playStoreImg">
    </a>
  </div>

</section>

<section id="how-it-works" class="how-it-works">
  <div class="container-fluid how-it-works-container">

    <div class="row">
      <div class="col-md-12 text-center">
                <div class="title">
          <h2> How It  <span style="color: #ed322c">Works</span></h2>
        </div>
      </div>
    </div>

    <div class="row">

      <div class="col-md-4 text-center">
        <div style="padding: 20px">
          <img src="../../assets/icon/reserve.png" alt="Reserve" style="height: 100px">
          <h3>1. Reserve</h3>
          <p>Select & reserve your favorite deal & receive immediate confirmation.</p>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <div style="padding: 20px">
          <img src="../../assets/icon/discount.png" alt="Redeem" style="height: 100px">
          <h3>2. Redeem</h3>
          <p>Redeem your deal by showing your confirmation to the waiting staff.</p>
        </div>
      </div>

      <div class="col-md-4 text-center">
        <div style="padding: 20px">
          <img src="../../assets/icon/meal.png" alt="Enjoy" style="height: 100px">
          <h3>3. Enjoy</h3>
          <p>Enjoy your meal and get your selected discount on your final bill.</p>
        </div>
      </div>

    </div>

  </div>
</section>

<section class="screenshotSection">


    <div class="container-fluid text-center">
      <div class="title">
        <h2> App <span style="color: #ed322c;font-weight: 900;">Screenshots</span></h2>
      </div>
      <p class="desc white">Here are some sample screenshots.</p>
    </div>
    <div class="container-fluid">
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide>
          <div class="screenshotMAin">
            <img src="../../assets/screenshots/1.png" alt="">
          </div></ng-template>
        <ng-template carouselSlide>
          <div class="screenshotMAin">
          <img src="../../assets/screenshots/2.png" alt="">
          </div></ng-template>
        <ng-template carouselSlide>
          <div class="screenshotMAin"><img src="../../assets/screenshots/3.png" alt=""></div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="screenshotMAin"><img src="../../assets/screenshots/4.png" alt=""></div>
        </ng-template>
      </owl-carousel-o>
    </div>

</section>


<section id="news" class="news container-fluid">

  <div class="">
    <div class="col-xs-12 text-center">
      <div class="title">
        <h2> APP'S  <span style="color: #ed322c">NEWS</span></h2>
      </div>
    </div>
  </div>


  <div class="col-xs-12 text-center">
    <h3><a>JANUARY 2020</a></h3>
      <p>Twidlee for iOS and Android is available for download for both customers and restaurants.</p>
      <p>App is scheduled to be launched before Feb 14th.</p>
  </div>

  <div class="col-xs-12 text-center">
    <h3><a >DECEMBER 2019</a></h3>
      <p>Over 70 restaurants are on board for Twidlee's launch in Lahore.
        This includes famous chains like KFC, Burger King, Subway, Second Cup, Gloria Jeans etc.</p>
      <p>Web panel is near completion. App development has entered its final stage.</p>
  </div>

  <div class="col-xs-12 text-center">
    <h3><a>OCTOBER - NOVEMBER 2019</a></h3>
      <p>Twidlee features, initial designs and web-panel functionalities have been finalized, development work officially started.</p>
      <p>Twidlee secures its first funding as it looks to build its team and develop the product.</p>
      <p>Twidlee Android, iOS and web panel development is on target. Web panel front end is near completion.</p>
  </div>

  <div class="col-xs-12 text-center">
    <h3><a >SEPTEMBER 2019</a></h3>
      <p>Lahore, Pakistan has been identified as Twidlee's proof of concept market.</p>
      <p>Twidlee.com has been registered.</p>
  </div>

</section>

<section  id="contact" class="contact container-fluid">

  <div class="col-xs-12 text-center mb-15">
    <div class="title">
      <h2> CONTACT  <span style="color: #ed322c">US</span></h2>
    </div>
  </div>


  <div class="col-sm-offset-2 col-sm-8 col-xs-12">
  <form  (ngSubmit)="onSubmit(f)" #f="ngForm" autocomplete="off">

    <div class="col-sm-6 col-xs-12">
      <div class="form-group">
        <input type="text" name="contact_name" #names="ngModel" required [(ngModel)]="name" autocomplete="off" class="input-name form-control" placeholder="Name"/>
        <span *ngIf="submitted && names.errors" class="formError glyphicon glyphicon-remove"></span>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <div class="form-group">
        <input name="contact_email" required #emails="ngModel" email pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" [(ngModel)]="email" class="input-email form-control" placeholder="Email"/>
        <span *ngIf="submitted && emails.errors" class="formError glyphicon glyphicon-remove"></span>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="form-group">
        <textarea name="contact_message" required class="textarea-message form-control" #messages="ngModel" [(ngModel)]="message" placeholder="Message" rows="4"></textarea>
        <span *ngIf="submitted && messages.errors" class="formError glyphicon glyphicon-remove"></span>
      </div>
      <button class="btn btn-default" type="submit" (click)="onSubmit(f)" [disabled]="buttonDisable">Send Now<i class="fa fa-paper-plane-o"></i></button>
    </div>



  </form>
  </div>



</section>



<section class="clearfix footer">

  <div class="col-xs-6">
    <p class="pt-15">Copyright 2020 Twidlee. All Rights Reserved.</p>
  </div>
  <div class="col-xs-6 text-right support">
    <a [routerLink]="['support']" target="_blank" style="margin-right: 20px">Support</a>
    <a [routerLink]="['privacy-policy']" target="_blank">Privacy Policy</a>
  </div>

</section>
