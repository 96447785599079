<nav class="navbar navbar-inverse">
  <div class="container-fluid" style="padding-left: 15px;padding-right: 15px">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#"><img src="../../assets/images/logo.png" alt="logo"></a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">

      <ul class="nav navbar-nav navbar-right">
        <li><a routerLink="/">HOME</a></li>
      </ul>
    </div>
  </div>
</nav>




<div style="background-color: #d01717;padding-top: 75px;padding-bottom: 20px" class="page-body">
  <div class="container">
    <h4 class="box-heading" id="signUpHeading">Restaurant Portal</h4>
    <div class="form-main">
      <form role="form" id="signUpForm" autocomplete="off"  data-parsley-validate novalidate  data-parsley-trigger="keyup">
        <div class="row p-30">
          <h4 class="box-heading" style="text-align: center;margin-bottom: 23px;">Limited Time Offer: <span style="color: #d02e27">Free 6 Months Membership</span></h4>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Restaurant Name<span class="star">*</span></label>
                <input class="form-control" name="restaurantName" id="restaurantName" required maxlength="5" maxlength="30" [(ngModel)]="createData.restaurant.name" parsley-trigger="change" type="text">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Street Address<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.address" class="form-control"
                       name="address" parsley-trigger="change" id="addressSearch" placeholder="Search location" required type="text">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Country<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.country" [disabled]="true" required class="form-control" id="country" name="country">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>State<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.state" [disabled]="true" required class="form-control" id="state" name="state">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>City<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.city" [disabled]="true" required class="form-control" parsley-trigger="change" id="city" name="city">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Area<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.area" required  class="form-control" id="area" name="area">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Zip Code<span class="star">*</span></label>
                <input [(ngModel)]="createData.restaurant.zip_code" required  class="form-control" id="zip_code" name="zip_code">
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Time Zone<span class="star">*</span></label>
                <select [(ngModel)]="createData.restaurant.time_zone" required class="form-control" name="time_zone">
                  <option value="" disabled>Select</option>
                  <option value="1">Canada Atlantic</option>
                  <option value="2">Canada Central</option>
                  <option value="3">Canada Eastern</option>
                  <option value="4">Canada Mountain</option>
                  <option value="5">Canada New Foundland</option>
                  <option value="6">Canada Pacific</option>
                  <option value="7">Canada Saskatchewan</option>
                  <option value="8">Canada Yukon</option>
                </select>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Pin Location</label>
                <div class="clearfix"></div>
                <input class="form-control" disabled name="pinLocationName" [value]="createData.restaurant.latitude + ', ' + this.createData.restaurant.longitude">
                <p class="btn custom-btn" id="mapBtn" style="float: right;margin-top: -34px;position: relative;"><i class="fa fa-map-marker" aria-hidden="true"></i></p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Restaurant Contact 1<span class="star">*</span></label>
                <input class="form-control" (keypress)="prevent($event)"
                       data-parsley-errors-container=".errorSpanBusinessPhone"
                       data-parsley-required-message="Enter Valid Number."
                       data-parsley-length-message="Enter Valid Number."
                       minlength="10" maxlength="10" required
                       name="business_contact" id="businessPhone" type="tel"   (keypress)="prevent($event)">
                <span class="errorSpanBusinessPhone"></span>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Restaurant Contact 2</label>
                <input class="form-control" name="business_contact_1"
                       data-parsley-errors-container=".errorSpanBusinessPhone1"
                       data-parsley-length-message="Enter Valid Number."
                       minlength="10" maxlength="10"
                       id="businessPhone1" type="tel"  pattern="^[1-9]+[0-9]*$"  (keypress)="prevent($event)">
                <span class="errorSpanBusinessPhone1"></span>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h4  class="box-heading" style="margin-top: 30px">Contact Person</h4>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Name<span class="star">*</span></label>
                <input class="form-control" name="name"  data-parsley-pattern="[a-zA-Z\s]+"  data-parsley-pattern-message="Name should be consist of alphabets." required type="text" [(ngModel)]="createData.name">
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Designation<span class="star">*</span></label>
                <select class="form-control" (change)="updateRole($event.target.value)">
                  <option *ngFor="let role of roleList" value="{{role.value}}">{{role.key}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Personal Contact<span class="star">*</span></label>
                <input class="form-control" id="phone"
                       data-parsley-errors-container=".errorSpanPhone"
                       data-parsley-required-message="Enter Valid Number."
                       data-parsley-length-message="Enter Valid Number."
                       minlength="10" maxlength="10" required
                       name="phone" type="tel" pattern="^[1-9]+[0-9]*$"  (keypress)="prevent($event)">
                <span class="errorSpanPhone"></span>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-6 m-t-15">
                <label>Email<span class="star">*</span></label>
                <input class="form-control" required parsley-type="email" type="email" name="email" [(ngModel)]="createData.restaurant.business_email">
              </div>
            </div>
          </div>
        </div>


        <div class="clearfix">
          <h3 class="title text-center page-title" style="font-size: 18px">Account Details</h3>
          <div class="row p-30">
            <div class="col-md-4 col-sm-4 col-xs-12 m-t-15">
              <label>User Name<span class="star">*</span></label>
              <input class="form-control" data-parsley-type='alphanum' required minlength="8" maxlength="15" placeholder="Minimum 8 characters" name="user_name" [(ngModel)]="createData.user_name">
              <span style="font-size: 12px;list-style: none;color: #d02e27;">Username will be minimum of 8 characters. <br>Username will be maximum of 16 characters.</span>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12 m-t-15">
              <label>Password<span class="star">*</span></label>
              <input id="password1" [(ngModel)]="createData.password" placeholder="Minimum 8 characters" name="password" type="password" class="form-control"
                     data-parsley-minlength="8"
                     data-parsley-maxlength="16"
                     data-parsley-errors-container=".errorspannewpassinput"
                     data-parsley-required-message="This value is required."
                     data-parsley-uppercase="1"
                     data-parsley-required>
              <span class="errorspannewpassinput"></span>
              <span style="font-size: 12px;list-style: none;color: #d02e27;">Password must contain at least (1) uppercase letter. <br> Password will be minimum of 8 characters. <br>Password will be maximum of 16 characters.</span>
            </div>
            <!--            data-parsley-lowercase="1"-->
            <!--            data-parsley-number="1"-->
            <!--            data-parsley-special="1"-->
            <div class="col-md-4 col-sm-4 col-xs-12 m-t-15">
              <label>Confirm Password<span class="star">*</span></label>
              <input name="Password_2" id="password2" type="password" class="form-control"
                     data-parsley-minlength="8"
                     data-parsley-maxlength="16"
                     data-parsley-errors-container=".errorspanconfirmnewpassinput"
                     data-parsley-required-message="Please re-enter your password."
                     data-parsley-equalto="#password1"
                     data-parsley-required />
              <span class="errorspanconfirmnewpassinput"></span>
            </div>
          </div>
        </div>


        <div class="clearfix">
          <h3 class="title text-center page-title"  style="font-size: 18px">Payment</h3>
          <div class="row p-30">
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div class="col-md-12 m-t-15">
                <label>Select Membership</label><br>
                <span class="font-size-19">
          <input type="radio" name="membership" checked value="0"> 6 Month Free Limited time offer
        </span> <br>
                <input class="" type="radio" disabled name="membership" value="1"> 1 Month - CAD 5000/Month<br>
                <input class="" type="radio" disabled name="membership" value="2"> 6 Months - CAD 4500/Month<br>
                <input class="" type="radio" disabled name="membership" value="3"> 1 Year - CAD 4000/Month<br>
              </div>

              <div class="col-md-12" style="margin-top: 20px">
                <p style="font-size: 11px"> By clicking <b>Submit</b>, I hereby agree to the Twidlee “Terms of Service” and “Privacy Policy”.</p>

                <!--              <p style="font-size: 10px"> By clicking on Start Submit Request I agree to <span class="external-link">Twidlee Terms</span>, including the <span class="external-link">payment terms</span>,-->
                <!--                and<span class="external-link"> Privacy Policy</span>. I agree to be charged US Rs. 0.00 per month, until I cancel my plan. Subscription can be canceled any time in the-->
                <!--                Twidlee dashboard.</p>-->
              </div>

            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">

              <div class="row m-t-15">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p class="m-b-0 font-size-19">Sub Total:</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <input type="text" class="form-control" disabled value="CAD 10,000">
                </div>
              </div>

              <div class="row m-t-15">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p class="m-b-0 font-size-19">Promo Code:</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <input type="text" class="form-control" disabled value="TWIDLEEFREE">
                </div>
              </div>

              <div class="row m-t-15">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p class="m-b-0 font-size-19">Promo Discount:</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <input type="text" class="form-control" disabled value="CAD 10,000">
                </div>
              </div>

              <div class="row m-t-15">
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <p class="m-b-0 font-size-19">Total:</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <input type="text" class="form-control" disabled value="CAD 0">
                </div>
              </div>

              <div class="text-right m-t-15">
                <button type="submit" (click)="SubmitData()" (submit)="SubmitData()" class="btn custom-btn">Submit Request</button>
              </div>

            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</div>


<div class="modal fade" id="mapModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none">
        <button type="button" class="close" data-dismiss="modal" style="margin-top: -10px">&times;</button>
        <h4 class="modal-title">
          <!--          <input class="form-control" placeholder="" name="map" id="address" parsley-trigger="change" required type="text">-->
        </h4>
      </div>
      <div class="modal-body" style="padding: 0px">
        <div id="addressMap" style="height: 400px;width: 100%"></div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="addressConform()">Confirm</button>
      </div>
    </div>

  </div>
</div>

<div class="modal fade" id="conformDialog" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" style="border-bottom: none">
        <button type="button" class="close" (click)="confirmationFun()" style="margin-top: -10px">&times;</button>
        <!--        <h4 class="modal-title">Modal Header</h4>-->
      </div>
      <div class="modal-body" style="text-align: center">
        <p>Thank you for joining Twidlee. We will contact you soon. You can email us at <a style="color: #59ADED" href="mailto:info@twidlee.com" target="_top">info@twidlee.com</a> for any information.</p>
      </div>
      <div class="modal-footer" style="padding-top: 0;border-top: 0;">
        <button type="button" class="btn btn-default" (click)="confirmationFun()">Ok</button>
      </div>
    </div>

  </div>
</div>


