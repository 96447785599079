import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HttpClientModule} from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import {FormsModule} from '@angular/forms';
import { SupportComponent } from './support/support.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterBusinessComponent } from './register-business/register-business.component';
import { RegisterBusinessCanadaComponent } from './register-business-canada/register-business-canada.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SupportComponent,
    PrivacyPolicyComponent,
    RegisterBusinessComponent,
    RegisterBusinessCanadaComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxUiLoaderModule,
    FormsModule,
    CarouselModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
