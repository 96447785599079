import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SupportComponent} from './support/support.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {HomeComponent} from './home/home.component';
import {RegisterBusinessComponent} from './register-business/register-business.component';
import {RegisterBusinessCanadaComponent} from './register-business-canada/register-business-canada.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'support', component: SupportComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'create', component: RegisterBusinessComponent },
  { path: 'create-canada', component: RegisterBusinessCanadaComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
