import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';

const Headers = new HttpHeaders({
  'Content-Type': 'application/json',
  'x-api-key': '5f7af37cb35f5cd8',
});
@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) {}
  getCountryList() {
    return this.http.get(Constants.apiAddress + 'v1/utilities/countries/',
      { headers: Headers });
  }
  signUp(createData) {
    return this.http.post(Constants.apiAddress + 'v2/auth/restaurant_registration/', JSON.stringify(createData),
      { headers: Headers });
  }
  getRole() {
    return this.http.get(Constants.apiAddress + 'v1/auth/get_user_types/',
      { headers: Headers });
  }
  sendMail(name, email, message, type) {return this.http.post('https://irvinetek-website-backend.herokuapp.com/business/send_mail', JSON.stringify({name, email, message, type }),
    { headers: Headers });
  }

  signUpCanada(createData) {
    return this.http.post(Constants.apiAddressCanada + 'v2/auth/restaurant_registration/', JSON.stringify(createData),
      { headers: Headers });
  }
  getRoleCanada() {
    return this.http.get(Constants.apiAddressCanada + 'v1/auth/get_user_types/',
      { headers: Headers });
  }
}

