import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {DataService} from '../data.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { OwlOptions } from 'ngx-owl-carousel-o';

declare var toastr: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  contactForm: NgForm;
  name = '';
  email = '';
  message = '';
  submitted = false;
  buttonDisable = false;
  type = 'twidlee';

  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
  constructor(private dataService: DataService, private ngxService: NgxUiLoaderService) { }

  ngOnInit(): void {
    setTimeout(() => {
    }, 1000);
  }

  onSubmit(form) {
    this.submitted = true;
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.buttonDisable = true;
    this.submitted = true;
    this.dataService.sendMail(this.name, this.email, this.message, this.type).subscribe(
      (data) => {
        console.log('fafef');
        this.ngxService.stop();
        window.scrollTo(0, 0);
        toastr.success('Message has been send successfully');
        this.buttonDisable = false;
        this.submitted = false;
        this.name = '';
        this.email = '';
        this.message = '';
      },
      (error) => {
        console.log('errorrrr');
        this.ngxService.stop();
        toastr.error('Something went wrong, please try again later');
        this.buttonDisable = false;
        this.submitted = true;
      }
    );

  }
}
