import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;
declare var google: any;
declare var toastr: any;
declare global {
  interface Window { Parsley: any; }
}

window.Parsley = window.Parsley || {};
@Component({
  selector: 'app-register-business-canada',
  templateUrl: './register-business-canada.component.html',
  styleUrls: ['./register-business-canada.component.css']
})
export class RegisterBusinessCanadaComponent implements OnInit {
  createData = {
    email: '',
    phone: '',
    name: '',
    password: '',
    user_name: '',
    user_type: '',
    restaurant: {
      business_email: '',
      name: '',
      zip_code: '',
      time_zone: '',
      address: '',
      longitude: 0,
      latitude: 0,
      area: '',
      city: '',
      state: '',
      country: '',
      business_contact: '',
      business_contact_1: ''
    }
  };
  roleList: any;
  countryList: any;
  stateList = [];
  cityList = [];
  areaList = [];
  pinLocationName = '';

  constructor(private dataService: DataService, private router: Router, private ngxService: NgxUiLoaderService, private chRef: ChangeDetectorRef) { }
  // for roles list
  getRolesList() {
    this.dataService.getRoleCanada().subscribe(
      (data) => {
        this.roleList = data;
        this.createData.user_type = this.roleList[0].value;
      },
      (error) => {
      }
    );
  }

  updateRole(value){
    this.createData.user_type = value;
  }

  SubmitData() {
    console.log(this.createData);
    var form = $('#signUpForm');
    form.parsley().validate();
    if (form.parsley().isValid()) {
      // if (  this.createData.restaurant.longitude === ''  || this.createData.restaurant.latitude === '') {
      //   toastr.error('Please select pin location');
      //   return;
      // }
      this.createData.restaurant.business_contact = '+' + $('#businessPhone').intlTelInput('getSelectedCountryData').dialCode + $('#businessPhone').val();
      if ( $('#businessPhone1').val() !== '' && $('#businessPhone1').val() !== null && $('#businessPhone1').val() !== undefined) {
        this.createData.restaurant.business_contact_1 = '+' + $('#businessPhone1').intlTelInput('getSelectedCountryData').dialCode + $('#businessPhone1').val();
      }
      this.createData.phone = '+' + $('#phone').intlTelInput('getSelectedCountryData').dialCode + $('#phone').val();
      this.createData.email = this.createData.restaurant.business_email;
      this.ngxService.start();
      this.dataService.signUpCanada(this.createData).subscribe(
        (data) => {
          this.ngxService.stop();
          window.scrollTo(0, 0);
          $('#conformDialog').modal('show');
          // $('#signUpForm').parsley().reset();
        },
        (error) => {
          this.ngxService.stop();
          window.scrollTo(0, 0);
          if ( error.error.message === 'E-mail server error' ) {
            $('#conformDialog').modal('show');
            return;
          }
          if ( error.status === 400 ) {
            toastr.error(error.error.message);
          }
          if ( error.status === 404 ) {
            toastr.error('Something went wrong');
          }
        }
      );
    }
  }
  confirmationFun(){
    $('#conformDialog').modal('hide');
    this.router.navigate(['']);
  }


  prevent($event) {
    if ($event.keyCode === 8 || $event.keyCode === 46) {
      return;
    }
    if (!($event.keyCode >= 48 && $event.keyCode <= 57)) {
      $event.preventDefault();
      return;
    }
  }

  initMap() {
    setTimeout(() => {
      var contax = this;
      var input = document.getElementById('address');
      var autocomplete = new google.maps.places.Autocomplete(input,{});
      google.maps.event.addListener(autocomplete, 'place_changed', function(){
        var place = autocomplete.getPlace();
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();
      });
    }, 5000);
  }

  addressConform(){
    $('#mapModal').modal('hide');
  }

  callMapFun() {
    var context = this;
    var address = this.createData.restaurant.city;
    if ( address === '' ) {
      toastr.error('Please select your city');
      return;
    }
    $('#mapModal').modal('show');
    var geocoder, map;
    geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(-0.397, 5.644);
    var options = {
      zoom: 12,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    map = new google.maps.Map(document.getElementById('addressMap'), options);
    geocoder.geocode({ 'address': address }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        map.setCenter(results[0].geometry.location);
        var marker = new google.maps.Marker({
          map: map,
          position: results[0].geometry.location
        });
        var lat = marker.getPosition().lat();
        var long = marker.getPosition().lng();
        context.callMap(lat, long);
      } else {
        // alert("Geocode unsuccessful");
      }
    });
  }


  callMap(latitude, longitude) {
    this.createData.restaurant.latitude = latitude;
    this.createData.restaurant.longitude = longitude;
    setTimeout(() => {
      var context = this;
      var map;
      var marker;
      var adr;
      var myLatlng = new google.maps.LatLng( latitude , longitude);
      var geocoder = new google.maps.Geocoder();
      var infowindow = new google.maps.InfoWindow();
      var mapOptions = {
        zoom: 14,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        navigationControl: false,
      };
      var endImage = {
        url: 'assets/pin.png', // url
        scaledSize: new google.maps.Size(50, 56), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(16, 16) // anchor
      };
      map = new google.maps.Map(document.getElementById('addressMap'), mapOptions);
      marker = new google.maps.Marker({
        map: map,
        position: myLatlng,
        draggable: true,
      });
      geocoder.geocode({'latLng': myLatlng }, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            // infowindow.setContent(results[0].formatted_address);
            // infowindow.open(map, marker);
            context.pinLocationName = results[0].formatted_address;
            context.createData.restaurant.latitude = marker.getPosition().lat();
            context.createData.restaurant.longitude = marker.getPosition().lng();
          }
        }
      });


      google.maps.event.addListener(marker, 'dragend', function() {
        geocoder.geocode({'latLng': marker.getPosition()}, function(results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              // infowindow.setContent(results[0].formatted_address);
              // infowindow.open(map, marker);
              context.pinLocationName = results[0].formatted_address;
              context.createData.restaurant.latitude = marker.getPosition().lat();
              context.createData.restaurant.longitude = marker.getPosition().lng();
            }
          }
        });
      });
    }, 500);
  }

  initAddressSearch() {
    setTimeout(() => {
      var contax = this;
      var input = document.getElementById('addressSearch');
      var autocomplete = new google.maps.places.Autocomplete(input,{});
      google.maps.event.addListener(autocomplete, 'place_changed', function(){
        var place = autocomplete.getPlace();
        console.log('placeplaceplaceplaceplaceplace', place);
        contax.createData.restaurant.address = '';
        contax.createData.restaurant.country = '';
        contax.createData.restaurant.state = '';
        contax.createData.restaurant.city = '';
        contax.createData.restaurant.area = '';
        contax.createData.restaurant.zip_code = '';
        contax.createData.restaurant.latitude = place.geometry.location.lat();
        contax.createData.restaurant.longitude = place.geometry.location.lng();
        contax.setAddreaa(place.address_components);
      });
    }, 2000);
  }
  setAddreaa(placeDetailData) {
    for(var i = 0; i < placeDetailData.length; i++) {
      for(var j = 0; j < placeDetailData[i].types.length; j++) {
        if (placeDetailData[i].types[j] === 'administrative_area_level_2') {
          this.createData.restaurant.city = placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'locality') {
          this.createData.restaurant.city = placeDetailData[i].long_name;
        }

        if (placeDetailData[i].types[j] === 'street_number') {
          this.createData.restaurant.address = placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'route') {
          this.createData.restaurant.address = this.createData.restaurant.address + ' ' + placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'sublocality_level_2') {
          this.createData.restaurant.address = this.createData.restaurant.address + ' ' + placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'sublocality_level_1') {
          this.createData.restaurant.address = this.createData.restaurant.address + ' ' + placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'administrative_area_level_1') {
          this.createData.restaurant.state = placeDetailData[i].long_name;
        }

        if (placeDetailData[i].types[j] === 'country') {
          this.createData.restaurant.country = placeDetailData[i].long_name;
        }
        if (placeDetailData[i].types[j] === 'sublocality') {
          if ( this.createData.restaurant.area !== '') {
            this.createData.restaurant.area = this.createData.restaurant.area + ', ' + placeDetailData[i].short_name;
          } else {
            this.createData.restaurant.area = this.createData.restaurant.area + ' ' + placeDetailData[i].short_name;
          }
        }
        if (placeDetailData[i].types[j] === 'locality') {
          if ( this.createData.restaurant.area !== '') {
            this.createData.restaurant.area = this.createData.restaurant.area + ', ' + placeDetailData[i].short_name;
          } else {
            this.createData.restaurant.area = this.createData.restaurant.area + ' ' + placeDetailData[i].short_name;
          }
        }
        if (placeDetailData[i].types[j] === 'postal_code') {
          this.createData.restaurant.zip_code =  placeDetailData[i].long_name;
        }
        if ( this.createData.restaurant.address === '' ) {
          this.createData.restaurant.address = placeDetailData[i].long_name;
        }
        console.log('area', placeDetailData[i]);
      }
    }
    setTimeout(() => {
      this.chRef.detectChanges();
    }, 100);
  }
  ngOnInit() {
    var contax = this;
    $( "#mapBtn" ).click(function() {
      contax.callMapFun();
    });
    this.initAddressSearch();
    this.getRolesList();
    // this.initMap();
    $('form').parsley();
    $('#phone, #businessPhone, #businessPhone1').intlTelInput({
      // autoFormat: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      autoPlaceholder: false,
      preferredCountries: ['CA'],
      preventInvalidNumbers: true,
      // stop the user from typing invalid dial codes
      preventInvalidDialCodes: true,
      formatOnDisplay: true,
      separateDialCode: true,
    });

    //has uppercase
    window.Parsley.addValidator('uppercase', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var uppercases = value.match(/[A-Z]/g) || [];
        return uppercases.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) uppercase letter.'
      }
    });

//has lowercase
    window.Parsley.addValidator('lowercase', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var lowecases = value.match(/[a-z]/g) || [];
        return lowecases.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) lowercase letter.'
      }
    });

//has number
    window.Parsley.addValidator('number', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var numbers = value.match(/[0-9]/g) || [];
        return numbers.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) number.'
      }
    });

//has special char
    window.Parsley.addValidator('special', {
      requirementType: 'number',
      validateString: function(value, requirement) {
        var specials = value.match(/[^a-zA-Z0-9]/g) || [];
        return specials.length >= requirement;
      },
      messages: {
        en: 'Your password must contain at least (%s) special characters.'
      }
    });

  }

}


